import { createAsyncThunk } from "@reduxjs/toolkit";
import { authAPI, checkSessionAPI } from "./apis";
import { toggleWalletErrorModal } from "../walletInfo/walletInfo.slicer";

export const checkSession = createAsyncThunk("auth/checkSession", async () => {
  return await checkSessionAPI();
});

export const connectWallet = createAsyncThunk(
  "auth/connectWallet",
  async (_, { getState, dispatch }) => {
    const {
      Web3Info: { Web3, web3Modal },
    } = getState();

    const provider = await web3Modal.connect();
    const web3Instance = new Web3(provider);
    let account = await web3Instance.eth.getAccounts();

    if (!account) {
      await provider.request({
        method: "eth_requestAccounts",
      });
      account = await web3Instance.eth.getAccounts();
    }

    account = account[0];

    const message = `Welcome to Minotopia!\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nWallet address: ${account}`;
    const signature = await web3Instance.eth.personal.sign(
      web3Instance.utils.fromUtf8(message),
      account
    );

    const payload = {
      message,
      signature,
    };

    try {
      const res = await authAPI(payload);
      return res;
    } catch (error) {
      if (error.response.status === 403) {
        dispatch(toggleWalletErrorModal(true));
      } else {
        throw error;
      }
    }

    return await authAPI(payload);
  }
);
