import { createSlice } from "@reduxjs/toolkit";
import { poolDataAction } from "./poolData.action";

const initialState = {
  poolData: {
    ltc_usd: {},
    stats: {},
    history: [],
  },
  poolDataLoading: false,
  poolDataLoadingSuccess: false,
  poolDataLoadingFailure: false,
};

const poolDattSlicer = createSlice({
  name: "poolData",
  initialState,
  extraReducers: {
    [poolDataAction.pending]: (state, action) => {
      state.poolDataLoading = true;
    },
    [poolDataAction.fulfilled]: (state, action) => {
      state.poolDataLoading = false;
      state.poolDataLoadingSuccess = true;
      state.poolData = { ...action.payload };
    },
    [poolDataAction.rejected]: (state, action) => {
      state.poolDataLoading = false;
      state.poolDataLoadingSuccess = false;
      state.poolDataLoadingFailure = true;
    },
  },
});
export default poolDattSlicer.reducer;
