import { createAsyncThunk } from "@reduxjs/toolkit";

export const initializeWebInfo = createAsyncThunk(
  "web3/initializeWebInfo",
  async () => {
    let Web3Modal = import(
      "web3modal"
      /* webpackPreload: true */
      /* webpackChunkName: "web3modal" */
    );
    let WalletConnectProvider = import(
      "@walletconnect/web3-provider"
      /* webpackPreload: true */
      /* webpackChunkName: "web3-provider" */
    );

    let _Web3 = import(
      "web3" /* webpackPreload: true */
      /* webpackChunkName: "Web3" */
    );

    let Web3;

    [
      { default: Web3Modal },
      { default: WalletConnectProvider },
      { default: Web3 },
    ] = await Promise.all([Web3Modal, WalletConnectProvider, _Web3]);

    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required

        options: {
          chainId: 1,
          infuraId: "492228b49c254865adaada0fdfe1f884", // required
          rpc: {
            1: "https://mainnet.infura.io/v3/492228b49c254865adaada0fdfe1f884", // mainnet ethereum
            4: "https://rinkeby.infura.io/v3/492228b49c254865adaada0fdfe1f884", // Rinkeby Test Network
            56: "https://bsc-dataseed.binance.org/", // BSC
            97: "https://data-seed-prebsc-2-s1.binance.org:8545", // BSC Testnet
            137: "https://polygon-mainnet.g.alchemy.com/v2/ma3nP6ZZCpI81yCWIBz2fPOD2BNBrVP5", // Polygon
            250: "https://rpc.ftm.tools", // Fantom
            4002: "https://rpc.testnet.fantom.network", // Fantom Testnet
            42161: "https://arb1.arbitrum.io/rpc", // Arbitrum
            80001: "https://rpc-mumbai.matic.today", // Mumbai
            421611: "https://rinkeby.arbitrum.io/rpc", // Arbitrum Testnet Rinkeby
          },
        },
      },
    };
    const web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions, // required
    });

    return {
      web3Modal,
      Web3,
    };
  }
);
