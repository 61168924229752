import { makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { poolDataAction } from "../../features/poolData/poolData.action";
import { Charts } from "../chart";
import ChartDataTile from "../chartDataTile";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

const ChartComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [button, SetButton] = useState(1);
  const [period, setPeriod] = useState("hour");
  const [selectedBtn, setSelectedBtn] = useState(1);
  const {
    poolData: {
      stats: { workers, hashrate, avg_hash, balance },
    },
  } = useSelector((state) => state.PoolData);
  const {
    poolData: { history, stats },
  } = useSelector((state) => state.PoolData);

  const handleChange = (props) => {
    dispatch(poolDataAction(props.name));
    setPeriod(props.detail);
    setSelectedBtn(props.selected);
  };
  const handleAlignment = (event, newAlignment) => {
    SetButton(newAlignment);
  };
  return (
    <div className={classes.chartsBox}>
      <div>
        <Typography className={classes.chartTitle}>miner pool data</Typography>
        <div className={classes.cardBox}>
          <ChartDataTile
            title="CURRENT HASHRATE"
            description={hashrate && hashrate}
          />
          <ChartDataTile
            title="AVERAGE HASHRATE"
            description={avg_hash && avg_hash}
          />
          <ChartDataTile title="BALANCE" description={balance && balance} />
          <ChartDataTile
            title="WORKERS"
            description={workers && `${workers}/3`}
          />
        </div>
      </div>
      {/* <ToggleButtonGroup
        disableElevation
        variant="contained"
        aria-label="contained primary button group"
        value={button}
        exclusive
        onChange={handleAlignment}
        className={classes.buttonGroup}
      >
        <ToggleButton
          className={classes.buttonGroup}
          onClick={() => {
            handleChange({
              name: "d",
              detail: "hour",
              selected: 1,
            });
          }}
          value={1}
        >
          1D
        </ToggleButton>
        <ToggleButton
          className={classes.buttonGroup}
          onClick={() => {
            handleChange({
              name: "w",
              detail: "day",
              selected: 2,
            });
          }}
          value={2}
        >
          1W
        </ToggleButton>
        <ToggleButton
          className={classes.buttonGroup}
          onClick={() => {
            handleChange({
              name: "m",
              detail: "day",
              selected: 3,
            });
          }}
          value={3}
        >
          1M
        </ToggleButton>
        <ToggleButton
          className={classes.buttonGroup}
          onClick={() => {
            handleChange({
              name: "y",
              detail: "month",
              selected: 4,
            });
          }}
          value={4}
        >
          1Y
        </ToggleButton>
        <ToggleButton
          className={classes.buttonGroup}
          onClick={() => {
            handleChange({
              name: "5y",
              detail: "year",
              selected: 5,
            });
          }}
          value={5}
        >
          5Y
        </ToggleButton>
        <ToggleButton
          className={classes.buttonGroup}
          onClick={() => {
            handleChange({
              name: "max",
              detail: "year",
              selected: 6,
            });
          }}
          value={6}
        >
          ALL
        </ToggleButton>
      </ToggleButtonGroup> */}

      <Charts history={history} timePeriod={period} workers={stats} />
    </div>
  );
};

export default ChartComponent;

const useStyles = makeStyles((theme) => ({
  chartsBox: {
    backgroundColor: "black",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "40px",
  },
  chartTitle: {
    background: "-webkit-linear-gradient(90deg, #FFF8AE 17.19%, #AE750D 100%)",
    fontWeight: 500,
    textTransform: "uppercase",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: 23,
    margin: "40px 0",
  },
  buttonGroup: {
    backgroundColor: "black",
    border: "none",
    fontWeight: "600",
    fontSize: "16px",
    borderRadius: 0,
    color: "rgba(218, 210, 131, 0.7)",
    "&.Mui-selected": {
      background:
        "-webkit-linear-gradient(90deg, #FFF8AE 17.19%, #AE750D 100%)",
      color: "black",
    },
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  cardBox: {
    display: "flex",
    gap: "50px",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
}));
