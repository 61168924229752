import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { checkSession } from "../features/auth/auth.actions";

export const useCheckSession = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkSession());
  }, [dispatch]);
};
