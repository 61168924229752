import { Button, ButtonGroup, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Charts } from "../chart";
import Divider from "../divider";
import ChartDataTile from "../chartDataTile";
import MiniCard from "../card";
import { useDispatch, useSelector } from "react-redux";
import { getMinerData } from "../../features/priceFeed/priceFeed.action.js";

import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TabLTC } from "../minerDataTabs";
import CoinPriceTile from "../coinPriceTile";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MinerData() {
  const classes = useStyles();
  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMinerData());
  }, [dispatch]);

  const minerData = useSelector((state) => state.PriceFeeds.minerData);
  return (
    <div className={classes.root}>
      <div className={classes.gradientSection}>
        <div>
          <Divider className={classes.fstdivider} />
        </div>
        <Typography className={classes.nftminted}>coin performance</Typography>

        <div>
          <Divider className={classes.secndDivider} />
        </div>
      </div>

      <div position="static" className={classes.tabs}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
          className={classes.mainTabs}
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "transparent" } }}
        >
          {Object.keys(minerData).map((key, index) => (
            <Tab
              label={
                <MiniCard
                  className={classes.mapMini}
                  wallet_address={key}
                  price={minerData[key]}
                  style={{ width: "100%" }}
                  width="100%"
                  decimal={4}
                />
              }
              {...a11yProps(index + 1)}
              className={classes.MuiTabRoot}
              disabled={index !== 1}
            />
          ))}
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <div className={classes.chartsBox}>{/* <TabLTC /> */}</div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabLTC />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {/* <TabLTC /> */}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {/* <TabLTC /> */}
      </TabPanel>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "transparent",
  },
  tabsBox: {
    width: "100%",
    flexGrow: 1,
  },
  tabs: {
    display: "flex",
    backgroundColor: "transparent",
  },
  mainTabs: {
    "&.MuiTabs-root": {
      width: "100%",
    },
  },

  tileGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
  },
  chartTitle: {
    color: "#DAD283",
    fontWeight: "500",
    fontSize: "23px",
    textShadow: "0px 1px 12px rgba(0, 0, 0, 0.53)",
    textTransform: "uppercase",
  },
  gradientSection: {
    paddingTop: 50,
    paddingBottom: 50,

    display: "flex",
    justifyContent: "center",
    gap: 30,
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      gap: 10,
    },
  },
  fstdivider: {
    width: 325,
    transform: "rotate(180deg) ",
    [theme.breakpoints.down("sm")]: {
      width: 120,
    },
  },

  secndDivider: {
    width: 325,
    [theme.breakpoints.down("sm")]: {
      width: 120,
    },
  },
  nftminted: {
    textAlign: "center",
    background:
      "-webkit-linear-gradient(90deg,  #FFF8AE  17.19%,  #AE750D 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: 500,
    fontSize: 33,
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      width: 100,

      fontWeight: 500,
      fontSize: 12,
    },
  },
  TopcardBox: {
    display: "flex",
    gap: "7.5px",
    margin: "10px 0",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  mapMini: {
    "&.main": {
      width: "100%",
    },
  },
  MuiTabRoot: {
    border: "0.5px solid green",
    padding: "0",
  },
}));
