import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCrytoConversionRate } from "./api";

export const getMinerData = createAsyncThunk(
  "CryptoConversionData",
  async () => {
    const { data } = await apiCrytoConversionRate();
    return data;
  }
);
