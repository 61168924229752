import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiWalletInfo, getConnectedWalletNFTsAPI } from "./apis";

export const getWalletInfo = createAsyncThunk(
  "walletInfo/getWalletInfo",
  async () => {
    const { data } = await apiWalletInfo();
    return data;
  }
);

export const getConnectedWalletNFTs = createAsyncThunk(
  "waletInfo/getConnectedWalletNFTs",
  async () => {
    const { data } = await getConnectedWalletNFTsAPI();
    return data;
  }
);
