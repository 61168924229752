import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { poolDataAction } from "../../features/poolData/poolData.action";
import ChartComponent from "../chartComponent";

export const TabLTC = ({ history }) => {
  const dispatch = useDispatch();
  const {
    poolData: { poolDataLoading },
  } = useSelector((state) => state.PoolData);

  useEffect(() => {
    dispatch(poolDataAction());
  }, [dispatch]);

  return <div>{poolDataLoading || <ChartComponent />}</div>;
};
