import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import logo from "../../assests/logo.png";
import discord from "../../assests/discord.png";

import TwitterIcon from "@material-ui/icons/Twitter";
import Button from "../../units/buttons";
import { useDispatch, useSelector } from "react-redux";
import { connectWallet } from "../../features/auth/auth.actions";

export default function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onWalletConnect = () => {
    dispatch(connectWallet());
  };
  const { isLoggedIn } = useSelector((state) => state.Auth);
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.LeftSection}>
        <Box className={classes.inner}>
          <Box className={classes.image}>
            <img className={classes.logo} src={logo} alt="img" />
          </Box>
        </Box>
        <Box className={classes.innerSecond}>
          <Box className="innerSec">
          <a
              href="https://www.minetopia.io"
              target="_blank"
              rel="noreferrer"
              className="typo"
            >
              Home
            </a>
            <Typography className="typo">faq</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.RightSection}>
        <Box className={classes.iconSection}>
        <a
            href="https://twitter.com/MinetopiaNFT"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon className="icontwitter" />
          </a>
          <a
            href="https://discord.gg/7vBPMtxS6J"
            target="_blank"
            rel="noreferrer"
          >
          <img className={classes.imageIcon} src={discord} alt="icon" />
          </a>
        </Box>
        <Box>
          {isLoggedIn ? (
            <Button className={classes.button} variant="outlined">
              Connected
            </Button>
          ) : (
            <Button
              className={classes.button}
              variant="outlined"
              onClick={onWalletConnect}
            >
              connect wallet
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    fontFamily: "Roboto",
    display: "flex",
    justifyContent: "space-between",
  },

  LeftSection: {
    display: "flex",

    gap: 60,
  },

  innerSecond: {
    " & .innerSec": {
      paddingTop: 20,
      fontFamily: "Roboto",
      display: "flex",
      gap: 30,

      "& .typo": {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 600,
        fontSize: 16,
        textTransform: "upperCase",
        color: "#000000",
        lineHeight: "1.5",
      },
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  RightSection: {
    display: "flex",
    gap: 20,

    "& .icontwitter": {
      width: 20,
      height: 20,
      color: '#000'
    },
  },
  iconSection: {
    paddingTop: 20,
    display: "flex",
    gap: 30,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  imageIcon: {
    width: 20,
    height: 20,
  },

  button: {
    marginTop: 10,
    padding: "5px 0px",
    width: 150,
  },

  logo: {
    [theme.breakpoints.down("xs")]: {
      width: 150,
    },
  },
}));