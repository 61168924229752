import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";

function MiniCard({
  wallet_address,
  price,
  symbol = "$",
  width = true,
  decimal,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.main} style={{ width: width }}>
      <Box className={classes.inner}>
        <Typography className={classes.walletAdd}>{wallet_address}</Typography>
      </Box>
      <Box>
        <Typography className={classes.price}>
          {symbol}&nbsp;{Number(price).toFixed(decimal)}
        </Typography>
      </Box>
    </Box>
  );
}

export default MiniCard;
const useStyles = makeStyles((theme) => ({
  main: {
    // minWidth: 200,
    // width: "100%",
    height: 85,
    borderRadius: 12,
    fontFamily: "Roboto",
    display: "flex",
    flexDirection: "column",
    justifyItems: "start center",
    padding: "5px 20px 5px 20px",
    backgroundColor: "#091206",
    [theme.breakpoints.down("sm")]: {
      justifyItems: "center",
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  inner: {
    paddingTop: 10,
  },
  walletAdd: {
    color: "#DAD283",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  price: {
    background:
      "-webkit-linear-gradient(90deg, #DAD283 18.66%, #AE750D 98.21%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: 400,
    fontSize: 28,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));
