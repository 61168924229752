import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { makeStyles } from "@material-ui/core";
import "chartjs-adapter-moment";
ChartJS.register(
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function Charts({ history, timePeriod, workers }) {
  const classes = useStyles();

  const timeLabels = history?.map((item) => {
    const time = new Date(item.timestamp);
    return `${time}`;
    // return `${time.getHours()}:${time.getMinutes()}`;
  });
  console.log(workers, "workers");
  const hashrate = history.map((item) => Number(item.hashrate));

  const data = {
    labels: timeLabels,
    datasets: [
      {
        label: "Hash Rate",
        data: hashrate,
        borderColor: "gold",
        backgroundColor: "gold",
        yAxisID: "y",
        tension: 0.5,
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: false,
        text: "Charte Title",
      },
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: timePeriod,
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "right",
        ticks: {
          color: "rgba(255, 248, 174, 0.6)",
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "left",

        grid: {
          drawOnChartArea: true,
          color: "rgba(255, 248, 174, 0.6)",
        },
        suggestedMin: 0,
        suggestedMax: workers.workers,
        ticks: {
          // forces step size to be 50 units
          stepSize: 1,
          color: "rgba(255, 248, 174, 0.6)",
        },
      },
    },
  };
  return <Line options={options} data={data} className={classes.chart} />;
}
const useStyles = makeStyles((theme) => ({
  chart: {
    backgroundColor: "black",
    width: "100%",
    borderRadius: "10px",
  },
}));


