import { Box, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import CollectionCard from "./index";
import { useDispatch, useSelector } from "react-redux";
import { getConnectedWalletNFTs } from "../../features/walletInfo/walletInfo.action";
import Divider from "../divider";

export default function CollectionCardMap() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { nfts } = useSelector((state) => state.WalletInfo.walletNFTs);
  const nftDataLoadingSuccess = useSelector(
    (state) => state.WalletInfo.nftDataLoadingSuccess
  );
  useEffect(() => {
    !nftDataLoadingSuccess && dispatch(getConnectedWalletNFTs());
  }, [dispatch, nftDataLoadingSuccess]);
  return (
    <Box>
      <Box className={classes.gradientSection}>
        <Box>
          <Divider className={classes.fstdivider} />
        </Box>
        <Typography className={classes.nftminted}>{`minted nft (${
          nfts ? nfts.length : 0
        })`}</Typography>

        <Box>
          <Divider className={classes.secndDivider} />
        </Box>
      </Box>
      <Box className={classes.gridContainer}>
        {nfts &&
          nfts.map((item) => {
            const { name, image, animation_url } = item.metadata;

            const image_ipfs_url = image.replace(
              "ipfs://",
              "https://ipfs.io/ipfs/"
            );
            return (
              <Box>
                <CollectionCard
                  name={name}
                  poster={image_ipfs_url}
                  videoSrc={animation_url}
                />
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}
const useStyles = makeStyles((theme) => ({
  gridContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 49,
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  gradientSection: {
    paddingTop: 50,
    paddingBottom: 50,

    display: "flex",
    justifyContent: "center",
    gap: 30,
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      gap: 10,
    },
  },
  fstdivider: {
    width: 325,
    transform: "rotate(180deg) ",
    [theme.breakpoints.down("sm")]: {
      width: 120,
    },
  },
  secndDivider: {
    width: 325,
    [theme.breakpoints.down("sm")]: {
      width: 120,
    },
  },
  nftminted: {
    textAlign: "center",
    background:
      "-webkit-linear-gradient(90deg,  #FFF8AE  17.19%,  #AE750D 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: 500,
    fontSize: 33,
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      width: 100,

      fontWeight: 500,
      fontSize: 12,
    },
  },
}));