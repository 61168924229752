import { Box, makeStyles } from "@material-ui/core";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import image from "../assests/main.png";

import Header from "../components/header";
import HeadSection from "../components/headSection";
import ConnectWallet from "../pages/connectWallet";
// import Privacy from "../pages/Privacy";
// import TermAndConditions from "../pages/TermsAndConditions";
import Wrapper from "../units/wrapper";

const Routers = () => {
  const classes = useStyles();
  return (
    <Router>
      <Box className={classes.imageContainer}>
        <Routes>
          <Route
            path="/"
            element={
              <Wrapper>
                <Header />
                <HeadSection />
              </Wrapper>
            }
          />
        </Routes>
        <Routes>
          <Route
            path="/authenticate"
            element={
              <Wrapper>
                <Header />
                <ConnectWallet />
              </Wrapper>
            }
          />
        </Routes>
      </Box>
    </Router>
  );
};

export default Routers;

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    background: `url(${image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minWidth: "100%",
    minHeight: "100vh",
  },
}));
