import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { toggleWalletErrorModal } from "../../features/walletInfo/walletInfo.slicer";
import Buttons from "../../units/buttons";

export const WalletErrorPopup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isWalletErrorModalOpen } = useSelector((state) => state.WalletInfo);
  const onClose = () => dispatch(toggleWalletErrorModal(false));

  return (
    <Dialog open={isWalletErrorModalOpen} onClose={onClose}>
      <Paper className={classes.dialog}>
        {" "}
        <Box display={"flex"} justifyContent="flex-end">
          <Box p={1} onClick={onClose}>
            <IconButton size="small">
              <CancelIcon />
            </IconButton>
          </Box>
        </Box>
        <DialogContent>
          <Box pb={2}>
            <Box>
              <Typography variant="h5" align="center">
                Unfortunately, You will not be able to view our data as you are
                not an eligible NFT holder. Please join our Discord to find out
                more.
              </Typography>
            </Box>

            <Box
              pt={3}
              pb={1}
              display="flex"
              justifyContent={"center"}
              alignItems="center"
            >
              <Buttons variant="outlined" onClick={onClose}>
                Okay
              </Buttons>
            </Box>
          </Box>
        </DialogContent>
      </Paper>
    </Dialog>
  );
};
const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: "#396B2B",
    color: "#fff",
  },
}));