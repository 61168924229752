import { createSlice } from "@reduxjs/toolkit";
import { connectWallet } from "./auth.actions";

const initialState = {
  // wallet info
  userInfo: {},
  isLoggedIn: false,
  isAuthLoading: false,
  isAuthFailed: false,
  failureMessage: null,
};

const authSlicer = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [connectWallet.pending]: (state, action) => {
      state.isLoggedIn = false;
      state.isAuthLoading = true;
    },
    [connectWallet.fulfilled]: (state, action) => {
      const user = action.payload;
      const { accessToken } = user;
      delete user["accessToken"];
      localStorage.setItem("jwt-minotopia", accessToken);
      state.userInfo = user;
      state.isLoggedIn = true;
      state.isAuthLoading = false;
    },
    [connectWallet.rejected]: (state, action) => {
      state.isAuthFailed = true;
      state.isAuthLoading = false;
      state.failureMessage = action.error?.message;
      if (action.error.code) {
        state.failureMessage = action.error.message; // update this message with custom message when user has not nfts in wallet
      }
    },
  },
});
export default authSlicer.reducer;
