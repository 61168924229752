import { makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

const CoinPriceTile = () => {
  const classes = useStyles();
  const { ltc_usd } = useSelector((state) => state.PoolData.poolData.ltc_usd);

  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.coinName}>LiteCoin</Typography>
      </div>
      <div>
        <Typography className={classes.price}>${ltc_usd}</Typography>
      </div>
    </div>
  );
};

export default CoinPriceTile;

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: "10px 10px 0 0",
    background: "#252525",
    opacity: 0.7,
    padding: 10,
  },
  coinName: {
    background:
      "-webkit-linear-gradient(90deg,  #FFF8AE  17.19%,  #AE750D 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    opacity: 1,
  },
  price: {
    background:
      "-webkit-linear-gradient(90deg,  #FFF8AE  17.19%,  #AE750D 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    opacity: 1,
    fontWeight: "bolder",
    fontSize: 20,
  },
}));
