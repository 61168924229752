import { combineReducers } from "redux";
import walletInfoSlicer from "./walletInfo/walletInfo.slicer";
import priceFeedSlicer from "./priceFeed/priceFeed.slicer";
import web3InfoSlicer from "./web3/web3.slicer";
import authSlicer from "./auth/auth.slicer";
import poolDataSlicer from "./poolData/poolData.slicer";

const rootReducer = combineReducers({
  WalletInfo: walletInfoSlicer,
  PriceFeeds: priceFeedSlicer,
  Web3Info: web3InfoSlicer,
  Auth: authSlicer,
  PoolData: poolDataSlicer,
});

export default rootReducer;
