import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const ChartDataTile = ({ title, description }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.description}>{description}</Typography>
    </div>
  );
};

export default ChartDataTile;
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "14px",
    color: " rgba(218, 210, 131, 0.7)",
    textShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
  },
  description: {
    color: "#6DCC49",
    fontWeight: "300",
    fontSize: "40px",
    textShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
  },
}));
