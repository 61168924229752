import React, { useEffect } from "react";
import { Box, Link, makeStyles } from "@material-ui/core";
import MiniCard from "./index.js";
import { useDispatch, useSelector } from "react-redux";
import { getWalletInfo } from "../../features/walletInfo/walletInfo.action.js";

export default function MappedMiniCard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWalletInfo());
  }, [dispatch]);
  const walletinfo = useSelector((state) => state.WalletInfo.walletinfo);
  return (
    <Box className={classes.mainContainer}>
      {walletinfo.map((wallet) => {
        // {
        //   "Treasury": {
        //     "wallet": "0x2307962b8E30aec64B92fe0DAdA7D687498FE0f2",
        //     "balance": "0.000162403677156"
        //   }
        // },

        // let arr = [1,3,4,5,6,7,8,9,10,11,12]
        // const [state, setStateu]= useState() //[value, dispatchFunction]
        // let [a,b,c]=  [1,3,4,5,6,7,8,9,10,11,12]
        const [key] = Object.keys(wallet); // [Treasury]
        // const keys = Object.keys(wallet) // [keys]

        // "Treasury": {
        //     "wallet": "0x2307962b8E30aec64B92fe0DAdA7D687498FE0f2",
        //     "balance": "0.000162403677156"
        //   }
        // let key = keys[0]
        const { wallet: account, balance, name } = wallet[key]; // wallet['treausry']

        // wallet:  {account} // to accesss internal properties of an obj

        // wallet = {}

        // wallet: xyz // rename

        // let obj = wallet['treausry'];
        // let acccount = obj.wallet;
        // let balance = obj.balance;

        // {
        //     "wallet": "0x2307962b8E30aec64B92fe0DAdA7D687498FE0f2",
        //     "balance": "0.000162403677156"
        //   }
        // const link = `https://etherscan.io/address/${account}`;
        const link = `https://bscscan.com/address/${account}`;
        return (
          <Link href={link} target="_blank">
            <MiniCard
              key={account}
              wallet_address={name}
              price={balance}
              symbol={"ETH"}
              className={classes.card}
              decimal={6}
            />
          </Link>
        );
      })}
    </Box>
  );
}
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    // width: "calc(100% - 60px)",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "30px",
    // justifyItems: "center",
    margin: 0,

    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      justifyItems: "baseline",
    },
  },
}));
