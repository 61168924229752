import { createSlice } from "@reduxjs/toolkit";
import { getMinerData } from "./priceFeed.action";

const initialState = {
  minerData: {},
  minerDataLoading: false,
  minerDataLoadingSuccess: false,
  minerDataLoadingFailure: false,
};

const minerDataSlicer = createSlice({
  name: "minerData",
  initialState,
  extraReducers: {
    [getMinerData.pending]: (state, action) => {
      state.minerDataLoading = true;
    },
    [getMinerData.fulfilled]: (state, action) => {
      state.minerDataLoading = false;
      state.minerDataLoadingSuccess = true;
      state.minerData = action.payload;
    },
    [getMinerData.rejected]: (state, action) => {
      state.minerDataLoading = false;
      state.minerDataLoadingSuccess = false;
      state.minerDataLoadingFailure = true;
    },
  },
});
export default minerDataSlicer.reducer;
