import { createSlice } from "@reduxjs/toolkit";
import { reducers } from "./reducers";
import { getConnectedWalletNFTs, getWalletInfo } from "./walletInfo.action";

const initialState = {
  walletinfo: [],
  walletinfoLoading: false,
  walletinfoLoadingSuccess: false,
  walletinfoLoadingFailure: false,
  walletNFTs: {},
  nftDataLoading: false,
  nftDataLoadingSuccess: false,
  nftDataLoadingFailure: false,

  isWalletErrorModalOpen: false,
};

const walletInfoSlicer = createSlice({
  name: "walletinfo",
  initialState,
  reducers: reducers,
  extraReducers: {
    [getWalletInfo.pending]: (state, action) => {
      state.walletinfoLoading = true;
    },
    [getWalletInfo.fulfilled]: (state, action) => {
      state.walletinfoLoading = false;
      state.walletinfoLoadingSuccess = true;
      state.walletinfo = action.payload;
    },
    [getWalletInfo.rejected]: (state, action) => {
      state.walletinfoLoading = false;
      state.walletinfoLoadingSuccess = false;
      state.walletinfoLoadingFailure = true;
    },
    [getConnectedWalletNFTs.pending]: (state, action) => {
      state.nftDataLoading = true;
      state.nftDataLoadingSuccess = false;
    },
    [getConnectedWalletNFTs.fulfilled]: (state, action) => {
      state.nftDataLoading = false;
      state.nftDataLoadingSuccess = true;
      state.walletNFTs = action.payload;
    },
    [getConnectedWalletNFTs.rejected]: (state, action) => {
      state.nftDataLoading = false;
      state.nftDataLoadingFailure = true;
    },
  },
});
export default walletInfoSlicer.reducer;
export const { toggleWalletErrorModal } = walletInfoSlicer.actions;