import { Box, makeStyles } from "@material-ui/core";
import React from "react";

export default function CollectionCard({ poster, videoSrc, name }) {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <video
        className="image"
        style={{
          objectFit: "cover",
          objectPosition: "center center",
        }}
        muted
        autoPlay
        loop
        playsInline
        poster={poster}
        src={videoSrc}
        onLoadedMetadata={function (e) {
          e.target.poster = null;
        }}
      />
      <p className={classes.title}>{name}</p>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    "& .image": {
      minWidth: 150,
      width: "250px",
      height: 260,
      borderRadius: "10px",
      position: "relative",
      top: 0,
      animation: `$Down 0.4s ${theme.transitions.easing.easeInOut} 1 `,

      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "&:hover": {
      position: "relative",
      animation: `$Up 0.4s ${theme.transitions.easing.easeInOut} 1 `,
      top: "-20px",
    },
  },
  "@keyframes Up": {
    "0%": {
      top: 0,
    },

    "100%": {
      top: "-20px",
    },
  },
  "@keyframes Down": {
    "0%": {
      top: "-20px",
    },

    "100%": {
      top: "0",
    },
  },
  title: {
    fontWeight: "400",
    background:
      "-webkit-linear-gradient(90deg,  #FFF8AE  17.19%,  #AE750D 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));
