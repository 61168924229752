import { createSlice } from "@reduxjs/toolkit";
import { connectWallet, initializeWebInfo } from "./web3.actions";

const initialState = {
  web3Modal: null,
  Web3: null,
  web3Instance: null,

  // wallet info

  userInfo: {},
  isLoggedIn: false,
  isAuthLoading: false,
  isAuthFailed: false,
  failureMessage: null,
};

const web3InfoSlicer = createSlice({
  name: "web3-info",
  initialState,
  extraReducers: {
    [initializeWebInfo.fulfilled]: (state, action) => {
      state.web3Modal = action.payload.web3Modal;
      state.Web3 = action.payload.Web3;
    },
  },
});
export default web3InfoSlicer.reducer;
