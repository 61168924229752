import { client } from "../../HTTP/axios.config";

export const apiWalletInfo = async () => {
  const accessToken = localStorage.getItem("jwt-minotopia");

  const res = await client.get("/api/v1/contract/wallets-balance", {
    headers: {
      Authorization: accessToken,
    },
  });

  return res;
};

export const getConnectedWalletNFTsAPI = async () => {
  const accessToken = localStorage.getItem("jwt-minotopia");

  const res = await client.get("/api/v1/contract/wallet-nfts", {
    headers: {
      Authorization: accessToken,
    },
  });
  return res;
};
