import { Divider as MUIDivider } from "@material-ui/core";

import { withStyles } from "@material-ui/core";

export const Divider = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0.1, 0),
    height: 1,
    background: "linear-gradient( 90deg,#AE750D 0%, #FFF8AE  17.19%,rgba(218, 210, 131, 0) 100%)",
  },
}))(MUIDivider);

export default Divider;
