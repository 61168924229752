import { alpha, Button, withStyles } from "@material-ui/core";

/**
 * @dev custom button designed upon Material UI's Button component. It accepts all props the MuI button accepts. moreover, the following props have been overridden. the default styles is contained primary
 * @props variant can be either contained or outlined
 * @props color can be primary or secondary
 */
export default withStyles((theme) => ({
  root: {
    borderWidth: 1,
    fontSize: 12,

    textAlign: "center",

    fontWeight: 700,
    fontsize: 21,
  },

  outlined: {
    color: "#fff",
    backgroundColor: "#46B104",

    margin: "0px 5px",
    borderRadius: 25,
    "&:hover": {
      backgroundColor: alpha("#46B104", 0.7),
    },
  },

  contained: {
    color: "#fff",
    backgroundColor: "#46B104",

    "&:hover": {
      backgroundColor: alpha("#46B104", 0.7),
    },
  },
}))(Button);
