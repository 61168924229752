import React from "react";
import { Box, makeStyles, Paper, Typography } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import PropTypes from "prop-types";
import MappedMiniCard from "../card/mapped";
import MinerData from "../minerData";
import CollectionCardMap from "../collectionCard/collectionCard";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function HeadSection() {
  const classes = useStyles();
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box className={classes.mainContainer}>
      <Paper className={classes.paperSection}>
        <Tabs
          className={classes.tabss}
          value={value}
          indicatorColor="none"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab
            className={classes.tab}
            label="My collection"
            {...a11yProps(0)}
          />

          <Tab
            className={classes.tabtwo}
            label="miner data"
            {...a11yProps(1)}
          />
        </Tabs>
      </Paper>
      <Box className={classes.panelBox}>
        <TabPanel value={value} index={0} className={classes.tabPanel}>
          <MappedMiniCard />
          <CollectionCardMap />
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabPanel}>
          <MinerData />
        </TabPanel>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: "90px  0px 0px 0px",
    width: "100%",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  paperSection: {
    // width: 530,
    borderRadius: 25,
    backgroundColor: "#589E3C",
    border: "2px solid #396B2B",
  },

  tabss: {
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "#396B2B",
      [theme.breakpoints.down("xs")]: {
        width: 240,
      },
    },
  },
  tab: {
    textTransform: "uppercase",
    width: 300,
    height: 50,
    borderTopLeftRadius: 23,
    borderBottomLeftRadius: 23,
    color: "#FFF8AE",
    fontWeight: 500,
    fontSize: 16,
    borderRight: "2px solid #396B2B",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      width: "110px",
    },
  },

  tabtwo: {
    width: 300,
    height: 50,
    borderTopRightRadius: 23,
    borderBottomRightRadius: 23,
    backgroundColor: "#589E3C",
    color: "#FFF8AE",
    fontWeight: 500,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      width: "110px",
    },
  },
  panelBox: {
    // paddingTop: 70,
    width: "100%",
  },
  
}));
