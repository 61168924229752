import { Button, makeStyles, Typography } from "@material-ui/core";

import React from "react";
import Buttons from "../../units/buttons";

const ConnectWallet = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        Connect Wallet To Continue
      </Typography>
      <Button className={classes.btn}>
        <Typography className={classes.btnText}>Connect Wallet</Typography>
      </Button>
    </div>
  );
};

export default ConnectWallet;

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "20px",
    background: "rgba( 96, 96, 96, 0.3 )",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: " blur( 4px )",
    borderRadius: " 10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    height: "calc(100vh - 150px)",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    background:
      "-webkit-linear-gradient(90deg,  #FFF8AE  17.19%,  #AE750D 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: 800,
    fontSize: "33px",
    lineHeight: "39px",
  },
  btn: {
    background:
      "linear-gradient(26.88deg, #091206 32.9%, #1C2519 70.34%, #21291E 83.26%)",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.5)",
    borderRadius: " 12px",
    padding: "15px 20px",
  },
  btnText: {
    background:
      "-webkit-linear-gradient(90deg,  #FFF8AE  17.19%,  #AE750D 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));