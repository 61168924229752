import { client } from "../../HTTP/axios.config";

export const authAPI = async (payload) => {
  const { data } = await client.post("/api/v1/auth/signin", payload);
  return data;
};

export const checkSessionAPI = async (payload) => {
  const accessToken = localStorage.getItem("jwt-minotopia");
  if (!accessToken) throw new Error("invalid access token");
  const { data } = await client.get("/api/v1/auth/session", {
    headers: {
      Authorization: accessToken,
    },
  });
  return data;
};
