import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { initializeWebInfo } from "../features/web3/web3.actions";

export const useInitializeWebInfo = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initializeWebInfo());
  }, [dispatch]);
};
