import ReduxProvider from "./features/store";
import Routers from "./routes";
import HooksWrapper from "./hooksWrapper";
import { WalletErrorPopup } from "./components/walletErrorPopup";

function App() {
  return (
    <ReduxProvider>
      <WalletErrorPopup />
      <HooksWrapper />
      <Routers />
    </ReduxProvider>
  );
}

export default App;
